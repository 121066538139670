export const date = () => {
    Inputmask("datetime", { inputFormat: "dd/mm/yyyy", jitMasking: true }).mask(document.querySelectorAll(".date"));
};

export const time = () => {
    Inputmask("datetime", { inputFormat: "HH:MM:ss", jitMasking: true }).mask(document.querySelectorAll(".time"));
};

export const dateTime = () => {
    Inputmask("datetime", { inputFormat: "dd/mm/yyyy HH:MM:ss", jitMasking: true }).mask(document.querySelectorAll(".date-time"));
};

export const cep = () => {
    Inputmask("99999-999", { jitMasking: true }).mask(document.querySelectorAll(".cep"));
};

export const cnpj = () => {
    Inputmask("99.999.999/9999-99", { jitMasking: true }).mask(document.querySelectorAll(".cnpj"));
};

export const cpf = () => {
    Inputmask("999.999.999-99", { jitMasking: true }).mask(document.querySelectorAll(".cpf"));
};

export const money = () => {
    Inputmask("currency", { radixPoint: ",", rightAlign: false }).mask(document.querySelectorAll(".money"));
};

export const cellphone = () => {
    Inputmask("(99) 99999-9999", { jitMasking: true }).mask(document.querySelectorAll(".cellphone"));
};

export const phone = () => {
    Inputmask(["(99) 9999-9999", "(99) 99999-9999"], { jitMasking: true }).mask(document.querySelectorAll(".phone"));
};


document.addEventListener("DOMContentLoaded", () => {
    date();
    time();
    dateTime();
    cep();
    cnpj();
    cpf();
    money();
    cellphone();
    phone();
});
