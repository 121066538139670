document.addEventListener("DOMContentLoaded", () => {
    _.on("change", "form.validation input", function () {
        validateElement(this);
    });
});

export const validateElement = $el => {
    let isValid = true;
    makeInputValid($el);
    let rules = $el.dataset.rules;

    // Verify required
    if ($el.required) {
        if (!required($el)) {
            isValid = false;
        }
    }

    // Verify input type email
    if ($el.getAttribute("type") === "email" && $el.value) {
        if (!email($el)) {
            isValid = false;
        }
    }

    // Verify if input has rules to validate
    if (rules) {
        // Separate string rules to array
        rules.split("|").forEach(rule => {
            if (rule.includes("required_without")) {
                let without = rule.split(":")[1];

                if (!requiredWithout($el, without)) {
                    isValid = false;
                }
            } else {
                // Call to rule function
                if (rule.includes(":")) {
                    rule = rule.split(":");

                    if (!window.FormValidation[rule[0]]($el, rule[1])) {
                        isValid = false;
                    }
                } else {
                    if (!window.FormValidation[rule]($el)) {
                        isValid = false;
                    }
                }
            }
        });
    }

    return isValid;
};

export const confirmed = $el => {
    let confirmation = $el.closest("form").querySelector(`[name="${$el.getAttribute("name")}_confirmation"]`).value;

    if ($el.value && confirmation && $el.value !== confirmation) {
        makeInputInvalid($el, `O campo ${getElName($el)} de confirmação não confere.`);
        return false;
    }

    return true;
};

export const cpf = $el => {
    if ($el.value && !Validate.cpf($el.value)) {
        makeInputInvalid($el, `CPF inválido.`);
        return false;
    }

    return true;
};

export const cnpj = $el => {
    if ($el.value && !Validate.cnpj($el.value)) {
        makeInputInvalid($el, `CNPJ inválido.`);
        return false;
    }

    return true;
};

export const cpf_cnpj = $el => {
    if ($el.value && !Validate.cpfCnpj($el.value)) {
        makeInputInvalid($el, `CPF/CNPJ inválido.`);
        return false;
    }

    return true;
};

export const email = ($el) => {
    if (!Validate.email($el.value)) {
        makeInputInvalid($el, `Preencha o campo ${getElName($el)} com um e-mail válido.`);
        return false;
    }

    return true;
};

export const min = ($el, length) => {
    if ($el.value && $el.value.length < length) {
        makeInputInvalid($el, `O campo ${getElName($el)} deve ter pelo menos ${length} caracteres.`);
        return false;
    }

    return true;
};

export const name = ($el) => {
    if (!Validate.name($el.value)) {
        makeInputInvalid($el, `Preencha o campo ${getElName($el)} com seu nome completo.`);
        return false;
    }

    return true;
};

export const required = ($el) => {
    if (inputIsEmpty($el)) {
        makeInputInvalid($el, `O campo ${getElName($el)} é obrigatório.`);
        return false;
    }

    return true;
};

export const requiredWithout = ($el, without) => {
    let $without = $el.closest("form").querySelector(`[name="${without}"]`);

    if (inputIsEmpty($without) && inputIsEmpty($el)) {
        makeInputInvalid($el, `O campo ${getElName($el)} é obrigatório quando "${getElName($without)}" não está presente.`);
        return false;
    }

    return true;
};

export const size = ($el, length) => {
    if ($el.value && $el.value.length != length) {
        makeInputInvalid($el, `O campo ${getElName($el)} deve ser ${length} caracteres.`);
        return false;
    }

    return true;
};

const getElName = $el => {
    return $el.dataset.name ? $el.dataset.name : $el.getAttribute("name");
};

const inputIsEmpty = $el => {
    if ($el.getAttribute("type") === "checkbox" || $el.getAttribute("type") === "radio") {
        let $checked = $el.closest("form").querySelector(`input[name="${$el.getAttribute("name")}"]:checked`);
        return !$checked;
    } else {
        return !$el.value;
    }
};

export const makeInputValid = $el => {
    $el.setAttribute("class", $el.getAttribute("class").replace("is-invalid", "").trim());
    let $invalidFeedback = $el.closest("div").querySelectorAll(".invalid-feedback");

    if ($invalidFeedback.length) {
        $invalidFeedback.forEach($element => $element.remove());
    }
};

export const makeInputInvalid = ($el, message) => {
    $el.setAttribute("class", $el.getAttribute("class") + " is-invalid");
    $el.insertAdjacentHTML("afterend", `<span class="invalid-feedback" role="alert"><strong>${message}</strong></span>`);
};
