let sticky = document.querySelector(".navbar").offsetTop;

window.addEventListener("scroll", function () {
    let $body = document.querySelector("body");

    if (this.pageYOffset > sticky) {
        $body.classList.add("fixed-navbar")
    } else {
        $body.classList.remove("fixed-navbar");
    }
});
