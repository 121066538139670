export const on = (event, selector, handler) => {
    document.addEventListener(event, function (e) {
        for (var target = e.target; target && target != this; target = target.parentNode) {
            if (target.matches(selector)) {
                handler.call(target, e);
                break;
            }
        }
    }, false);
};

export const offset = element => {
    // Return zeros for disconnected and hidden (display: none) elements (gh-2310)
    // Support: IE <=11+
    // Running getBoundingClientRect on a
    // disconnected node in IE throws an error
    if (!element.getClientRects().length) {
        return { top: 0, left: 0 };
    }

    // Get document-relative position by adding viewport scroll to viewport-relative gBCR
    let rect = element.getBoundingClientRect();
    let win = element.ownerDocument.defaultView;
    return {
        top: rect.top + win.pageYOffset,
        left: rect.left + win.pageXOffset
    };
};

export const scrollToY = (y, duration = 0, element = document.scrollingElement) => {
    // cancel if already on target position
    if (element.scrollTop === y) return;

    const cosParameter = (element.scrollTop - y) / 2;
    let scrollCount = 0, oldTimestamp = null;

    function step(newTimestamp) {
        if (oldTimestamp !== null) {
            // if duration is 0 scrollCount will be Infinity
            scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
            if (scrollCount >= Math.PI) return element.scrollTop = y;
            element.scrollTop = cosParameter + y + cosParameter * Math.cos(scrollCount);
        }
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
}

export const scrollToTop = (duration = 0) => {
    scrollToY(0, duration, document.scrollingElement);
}

export const fadeIn = (element, duration = 800) => {
    element.style.opacity = 0;
    element.style.display = "";
    let dist = 1, start = null;

    function step(timestamp) {
        if (!start) start = timestamp;
        let progress = timestamp - start;
        element.style.opacity = Math.min((progress * dist) / duration, 1);

        if (progress < duration) {
            window.requestAnimationFrame(step);
        }
    }
    window.requestAnimationFrame(step);
};

export const fadeOut = (element, duration = 500) => {
    if (element.style.display === "none") return;
    element.style.opacity = 1;
    let dist = 1, start = null;

    function step(timestamp) {
        if (!start) start = timestamp;
        let progress = timestamp - start;
        element.style.opacity = Math.max(dist - ((progress * dist) / duration), 0);

        if (progress < duration) {
            window.requestAnimationFrame(step);
        } else {
            element.style.display = "none";
        }
    }
    window.requestAnimationFrame(step);
};
