export const prepend = message => {
    let $body = document.querySelector("body");

    $body.classList.add("modal-open");
    $body.insertAdjacentHTML(
        "afterbegin",
        `<div class="loading">
            <div class="spinner-border" role="status"></div>
            ${message ? `<span class="loading-message">${message}</span>` : ""}
        </div>`
    );
};

export const remove = () => {
    document.querySelector("body").classList.remove("modal-open");
    document.querySelector(".loading").remove();
};
