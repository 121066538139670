const create = (type, message) => {
    Swal.fire({
        icon: type,
        title: message,
        timer: 3000,
        buttonsStyling: false,
        customClass: {
            popup: "rounded",
            confirmButton: "btn btn-success",
        }
    });
};

export const success = message => {
    create("success", message);
};

export const error = message => {
    create("error", message);
};
