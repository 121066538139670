export const cpf = value => {
    value = value.replace(/\D/g, "");

    // Checks if all digits are the same
    if (/(\d)\1{10}/.test(value)) {
        return false;
    }

    // Calculate
    for (let t = 9; t < 11; t++) {
        let d, c;
        for (d = 0, c = 0; c < t; c++) {
            d += value[c] * ((t + 1) - c);
        }
        d = ((10 * d) % 11) % 10;
        if (value[c] != d) {
            return false;
        }
    }

    return true;
};

export const cnpj = value => {
    value = value.replace(/\D/g, "");

    // Checks if all digits are the same
    if (/(\d)\1{13}/.test(value)) {
        return false;
    }

    let i, j, soma, resto;

    // Validate first checker digit
    for (i = 0, j = 5, soma = 0; i < 12; i++) {
        soma += value[i] * j;
        j = (j == 2) ? 9 : j - 1;
    }

    resto = soma % 11;

    if (value[12] != (resto < 2 ? 0 : 11 - resto)) {
        return false;
    }

    // Validate second checker digit
    for (i = 0, j = 6, soma = 0; i < 13; i++) {
        soma += value[i] * j;
        j = (j == 2) ? 9 : j - 1;
    }

    resto = soma % 11;

    return value[13] == (resto < 2 ? 0 : 11 - resto);
};

export const cpfCnpj = value => {
    value = value.replace(/\D/g, "");

    if (value.length == 11) {
        return cpf(value);
    }

    if (value.length == 14) {
        return cnpj(value);
    }

    return false;
};

export const email = value => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
};

export const name = value => {
    return /^[A-zÀ-ú]+ [A-zÀ-ú]+/.test(value);
};
