export const isValid = $form => {
    let isValid = true;

    // Get all inputs
    $form.querySelectorAll("input:not([type=hidden]), select, textarea").forEach($el => {
        if (!FormValidation.validateElement($el)) {
            isValid = false;
        }
    });

    return isValid;
};

export const button = $form => {
    return $form.querySelector('button[type="submit"]');
};

export const buttonLoading = $form => {
    let $button = button($form);
    $button.disabled = true;
    $button.innerHTML = `<div class="spinner-grow"></div>`;
};

export const buttonSend = $form => {
    let buttonText = $form.dataset.buttonText ? $form.dataset.buttonText : "Enviar";
    let $button = button($form);
    $button.disabled = false;
    $button.innerHTML = buttonText;
};

const showAlert = ($form, type, message) => {
    $form.insertAdjacentHTML("afterbegin", `
        <div class="alert alert-${type === "success" ? "success" : "danger"} alert-dismissible fade show" role="alert">
            ${message}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
    `);
};

export const showSuccess = ($form, message) => {
    showAlert($form, "success", message);
};

export const showError = ($form, message) => {
    showAlert($form, "error", message);
};

export const removeAlert = $form => {
    let $response = $form.querySelector(".response");

    if ($response) {
        $response.remove();
    }
};

export const goUp = $form => {
    _.scrollToY(_.offset($form).top - 150, 1000);
};
