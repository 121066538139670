document.addEventListener("DOMContentLoaded", () => {
    _.on("submit", "form.lead", function (e) {
        e.preventDefault();
        e.stopPropagation();
        let $form = this;
        let origin = $form.dataset.origin;
        Form.buttonLoading($form);
        Form.removeAlert($form);

        if (!Form.isValid($form)) {
            Form.buttonSend($form);

            if (origin !== "Whatsapp") {
                Form.goUp($form);
            }

            return;
        }

        let data = new FormData($form);
        data.append("origin", origin);

        if ($form.dataset.grecaptcha) {
            grecaptcha.ready(async () => {
                let clientKey = $form.dataset.grecaptcha;
                data.append("g-recaptcha-response", await grecaptchaToken(clientKey, origin));
                send($form, data, origin);
            });
        } else {
            send($form, data, origin);
        }
    });
});

const send = ($form, data, origin) => {
    fetch("api/leads", {
        method: "POST",
        body: data,
    })
        .then(response => {
            if (!response.ok) {
                Form.showError($form, "Houve um erro ao enviar, tente novamente.");
                return;
            }

            let name = $form.querySelector('input[name="name"]').value;

            if (origin === "Whatsapp") {
                let numbers = $form.dataset.numbers.split("|");
                let random = Math.floor(Math.random() * numbers.length);
                let number = numbers[random].replace(/[^0-9]/g, "");
                window.open("https://api.whatsapp.com/send?phone=55" + number, "_blank");
            } else {
                let success = $form.dataset.success ? $form.dataset.success : "sua mensagem foi enviada com sucesso! Retornaremos em breve";
                Form.showSuccess($form, `${name}, ${success}.`);
            }
        })
        .then(() => {
            $form.reset();
            Form.buttonSend($form);

            if (origin !== "Whatsapp") {
                Form.goUp($form);
            }
        });
};

const grecaptchaToken = (clientKey, origin) => {
    origin = origin.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return grecaptcha.execute(clientKey, { action: origin });
};
