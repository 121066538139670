if (localStorage.getItem("policies") === null) {
    document.querySelector(".policies-bottom").style.display = "block";
}

if (localStorage.getItem("policies") === "true") {
    document.write(`
        <!-- Global site tag (gtag.js) - Google Analytics -->
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-59158563-3"></script>
        <script>
            window.dataLayer = window.dataLayer || [];
        
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('js', new Date());
        
            gtag('config', 'UA-59158563-3');
        </script>`);
}

document.querySelector("#btn-policy").addEventListener("click", function() {
    localStorage.setItem("policies", true);
    document.querySelector(".policies-bottom").style.display = "none";
});
