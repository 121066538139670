window.addEventListener("scroll", function () {
    let $gotop = document.querySelector(".gotop");

    if (this.pageYOffset > 160) {
        !$gotop && document.querySelector("body").insertAdjacentHTML("afterbegin", `
            <button type="button" class="gotop">
                <span class="sr-only">Ir para o topo</span>
            </button>
        `);
    } else {
        $gotop && $gotop.remove();
    }
});

_.on("click", ".gotop", () => {
    _.scrollToTop(1000);
});
