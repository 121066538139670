window._ = require("../helpers");
window.Validate = require("../validate");

require("./gotop");
require("./deprecated");
window.Loading = require("../loading");

window.Mask = require("../mask");
window.SweetAlert = require("../sweetalert");

window.FormValidation = require("../form-validation");
window.Form = require("../form");
require("../form-lead");

require("./includes/whatsapp");
require("./includes/policy");

require("./layouts/header");

require("./recaptcha");
