if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
    open("microsoft-edge:" + location);

    document.querySelector("body").insertAdjacentHTML("beforeend", `
        <div class="alert alert-warning alert-dismissible fade show fixed-bottom mb-0 text-body font-weight-bold" role="alert">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="currentColor"><path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/><path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/></svg>
                    </div>
                    <div class="col-md pl-md-0">
                        Nosso site é desenvolvido para os navegadores mais recentes. Atualize agora e tenha a melhor experiência.
                    </div>
                    <div class="col-md-auto">
                        <a class="text-body" href="https://www.google.com/intl/pt-BR/chrome/" target="_blank">
                            <img src="./images/web/brands/chrome.png" alt="Chrome" width="40" height="40">
                            <span class="ml-2">Baixar Chrome</span>
                        </a>
                    </div>
                    <div class="col-md-auto">
                        <a class="text-body" href="https://www.microsoft.com/pt-br/edge" target="_blank">
                            <img src="./images/web/brands/edge.png" alt="Edge" width="40" height="40">
                            <span class="ml-2">Baixar Edge</span>
                        </a>
                    </div>
                    <div class="col-md-auto">
                        <a class="text-body" href="https://www.mozilla.org/pt-BR/firefox/new/" target="_blank">
                            <img src="./images/web/brands/firefox.png" alt="Firefox" width="40" height="40">
                            <span class="ml-2">Baixar Firefox</span>
                        </a>
                    </div>
                </div>

                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    `);
}
